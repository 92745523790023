<template>
	<div class="notice_popup human int">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>

		<div>
			<div class="tit" style="margin-left: 5px;">NCS 직무자가평가 추가하기</div>
			<div class="member" style="line-height: 35px;">‘NCS 직무자가평가’를 추가로 진행하시겠습니까?</div>
		</div>
		
		<div class="select-btn">
			<div class="btn" @click="pageMove('myPage')">마이페이지로 가기</div>
			<div class="btn" @click="$emit('close')">평가하러가기</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['param'],
	data() {
		return {
			popDiv : this.param,
		}
	},

	methods : {
		pageMove(div) {
			if(div == 'myPage') {
				this.$emit('close');
				this.$router.push('MYP101M01');
			}
		}
	}
}
</script>